import React, { Component } from 'react';
import { login } from '../../src/util/APIUtils';
import { Link } from 'react-router-dom';
import { Form, Tabs, Radio, Checkbox, Button,  Typography, Divider, notification, Input, Icon } from 'antd';
import { ACCESS_TOKEN } from '../../src/constants';
import './registration.css';
//import { useLocation } from "react-router-dom";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const { TabPane } = Tabs;
const { Title, Paragraph, Text } = Typography;

//const location = useLocation();

function callback(key) {
    console.log(key);
  }

  function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
  }

const plainOptions = ['Apple'];
const options = [
  { label: 'Apple', value: 'Apple' },
  
];
/*const optionsWithDisabled = [
  { label: 'Apple', value: 'Apple' },
  
];*/

class Registration extends Component {


      constructor(props) {
        super(props);
        this.state = {

          mode: 'top',
            
            usernameOrEmail: {
                value: ''
            },
            password: {
                value: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.validateUsernameAvailability = this.validateUsernameAvailability.bind(this);
        //this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        //this.isFormInvalid = this.isFormInvalid.bind(this);
    }

    componentDidMount(){

      console.log(this.props.location.state.password);
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }


    
      handleModeChange = e => {
        const mode = e.target.value;
        this.setState({ mode });
      };
      handleSubmit(event) {
        event.preventDefault(); 
        
        
     const loginRequest = {
       
       
          usernameOrEmail: this.props.location.state.username,
          password: this.props.location.state.password
         

      }

    
          console.log(loginRequest);
          login(loginRequest)
                .then(response => {
                    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                    this.props.onLogin();
                    

                }).catch(error => {
                  console.log(error.status)
                    if(error.status === 401) {
                        notification.error({
                            message: 'Virtual Patient Portal',
                            description: 'Your Username or Password is incorrect. Please try again!'
                        });                    
                    } else {
                        notification.error({
                          
                            message: 'Virtual Patient Portal',
                            description: error.message || 'Opps !!Sorry! Something went wrong. Please try again!'

                          
            
                          });                                            
                        }
                    });
               // }
           // });
        }
                            
                    

    render() {
        const { state } = this.props.location;
        const { mode } = this.state;
        const { getFieldDecorator } = this.props.form;
      
        return (
            <Typography>
           <Title level={4}>Questionaire</Title>
    <Paragraph>
    Please complete your health questionaire at the best of your ability 
    </Paragraph>
    <Form onSubmit={this.handleSubmit} className="login-form">
    
      

    <FormItem>
              
          <div> 
            
            <Tabs onChange={callback} type="card">
            <TabPane tab="Family History" key="1">
            <Title level={4}>Family History</Title>   
            <div>
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
    
       <div><strong>Mother: (Please select all that apply)</strong></div>
        <Checkbox value="A">Healthy</Checkbox><br />
        <Checkbox value="B">Alcoholism</Checkbox><br />
        <Checkbox value="C">Bleeding Disorders</Checkbox><br />
        <Checkbox value="D">Celiac Disease</Checkbox><br />
        <Checkbox value="E">Colon Cancer</Checkbox><br />
        <Checkbox value="E1">Colon Polyps</Checkbox><br />
        <Checkbox value="E2">Chrons Disease</Checkbox><br />
        <Checkbox value="E3">Diabetes</Checkbox><br />
        <Checkbox value="E4">Heart Trouble</Checkbox><br />
        <Checkbox value="E5">Liver Disease</Checkbox><br />
        <Checkbox value="E6">Pancreatic Cancer</Checkbox><br />
        <Checkbox value="E7">Stomach Cancer</Checkbox><br />
        <Checkbox value="E8">Stroke</Checkbox><br />
        <Checkbox value="E9">Thyroid Disease</Checkbox><br />
        <Checkbox value="E10">Ulcer Disease</Checkbox><br />
        <Checkbox value="E11">Ulcerative Colitis</Checkbox><br />


        <br />
        
        <div><strong>Father: (Please select all that apply)</strong></div>
        <Checkbox value="A1">Healthy</Checkbox><br />
        <Checkbox value="B2">Alcoholism</Checkbox><br />
        <Checkbox value="C3">Bleeding Disorders</Checkbox><br />
        <Checkbox value="D4">Celiac Disease</Checkbox><br />
        <Checkbox value="F0">Colon Cancer</Checkbox><br />
        <Checkbox value="F01">Colon Cancer</Checkbox><br />
        <Checkbox value="F1">Colon Polyps</Checkbox><br />
        <Checkbox value="F2">Chrons Disease</Checkbox><br />
        <Checkbox value="F3">Diabetes</Checkbox><br />
        <Checkbox value="F4">Heart Trouble</Checkbox><br />
        <Checkbox value="F5">Liver Disease</Checkbox><br />
        <Checkbox value="F6">Pancreatic Cancer</Checkbox><br />
        <Checkbox value="F7">Stomach Cancer</Checkbox><br />
        <Checkbox value="F8">Stroke</Checkbox><br />
        <Checkbox value="F9">Thyroid Disease</Checkbox><br />
        <Checkbox value="F10">Ulcer Disease</Checkbox><br />
        <Checkbox value="F11">Ulcerative Colitis</Checkbox><br />
  </Checkbox.Group>
  </div>
            </TabPane>
            <TabPane tab="Social Hitory" key="2">
             
            <Title level={4}>Tobacco Use/Smoking</Title> <br />

            <div><strong>Are you a?</strong></div><br />
              <Radio value="t1"> current smoker</Radio><br />
              <Radio value="t2"> current everyday smoker</Radio><br />
              <Radio value="t3"> current some day smoker</Radio><br />
              <Radio value="t4"> Smoker current status unknown</Radio><br />
              <Radio value="t4"> former smoker</Radio><br />
              <Radio value="t5"> nonsmoker</Radio><br />
              <Radio value="t6"> unknown if ever smoke</Radio><br />
              <Radio value="t7"> light tobacco smoker</Radio><br />
              <Radio value="t8"> heavy tobacco smoker</Radio><br />
              <Radio value="t9"> Uses tobacco in other forms</Radio><br /> <br />
              <div><strong>Type:</strong></div><br />
              <Checkbox value="F5">Cigaretttes</Checkbox><br />
        <Checkbox value="FT6">Cigar</Checkbox><br />
        <Checkbox value="FT7">Chewing</Checkbox><br />
        <Checkbox value="FT8">Tobacco Type</Checkbox><br />
        <Checkbox value="FT9">Thyroid Disease</Checkbox><br />
        <Checkbox value="FT10">Vape/ECigarette</Checkbox><br /><br />
        
        <Title level={4}>Drugs</Title> <br />
        <div><strong>Have you used drugs other than those for medical reasons in the past 12 months?</strong></div><br />
        <Radio value="D10">Yes</Radio>
              <Radio value="D11">No</Radio><br /> <br />
              <Title level={4}>Alcohol</Title> <br />
        <div><strong>Did you have a drink containing alcohol in the past year</strong></div><br />
        <Radio value="D12">Yes</Radio>
              <Radio value="D13">No</Radio><br /> <br />
            </TabPane>
            <TabPane tab="Medical History" key="3">
               <p>Please select all that apply</p> 
            <Title level={4}>Past Medical History</Title> <br />

            <div><strong>ACID/Pacemaker</strong></div><br />
              <Radio value="t1">Yes</Radio><br /><br />
              <div><strong>Anemia</strong></div><br />
              <Radio value="t2"> Yes</Radio><br /><br />
              <div><strong>Chest Pain</strong></div><br />
              <Radio value="t3"> Yes</Radio><br /><br />
              <div><strong>Arthritis</strong></div><br />
              <Radio value="t4"> Yes</Radio><br /><br />
              <div><strong>Asthma</strong></div><br />
              <Radio value="t4"> Yes</Radio><br /><br />
              <div><strong>Bleeding Problems</strong></div><br />
              <Radio value="t5"> Yes</Radio><br /><br />
              <div><strong>Cancer, Colon</strong></div><br />
              <Radio value="t6"> Yes</Radio><br /><br />
              <div><strong>High Choleterol</strong></div><br />
              <Radio value="t7"> Yes</Radio><br /><br />
              <div><strong>IBS</strong></div><br />
              <Radio value="t8"> Yes</Radio><br /><br />
              <div><strong>High Blood Pressure</strong></div><br />
               <Radio value="t9"> Yes</Radio><br /> <br />
            </TabPane>
          </Tabs>

          </div> 
          <br />
          <br />
          {/*<Link to="/login"><Button type="primary" htmlType="submit">
            Submit
        </Button></Link>*/}
          </FormItem>
          <FormItem>
                    <Button type="primary" htmlType="submit" size="medium">Submit</Button>
                    
                </FormItem>
                </Form>
</Typography>


              
        );
    }
  }
//export default Registration;
//const WrappedNormalRegForm = Form.create({ name: 'normal_login' })(Registration);
export default Form.create()(Registration);
//export default WrappedNormalRegForm;