import React, { Component } from 'react';
import { login } from '../util/APIUtils';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';

import { Button, Table } from 'antd';


const columns = [
  {
    title: 'Medication Info',
    dataIndex: 'name',
  },
  
];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `Omeprazole 20 MG TAKE ONE CAPSULE BY MOUTH ONE TIME DAILY Oral 30
    #90 Unspecified with 3 refill(s) ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}


class MedicationList extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
            Refill Request
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={data} bordered/>
      </div>
    );
  }
}

export default MedicationList;
