import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
//import './index.css';
import { Table, Button } from 'antd';

const columns = [
  {
    title: 'Date / Time',
    dataIndex: 'name',
  },
 
  {
    title: 'Provider',
    dataIndex: 'address',
  },

  {
    title: 'Department',
    dataIndex: 'department',
  },
];

const data = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    name: `Thursday Feburay 11, 2021 4:00 PM ${i}`,
    address: `Dr M Meirer Davilla ${i}`,
    department: `Family Practice Coral Springs. ${i}`,
  });
}

class CancelAppointment extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
            Cancel
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      </div>
    );
  }
}

export default CancelAppointment;
          