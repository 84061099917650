export const data = {

medications : [
{ id: 1, name:"atorvastatin 20 mg tablet", instructions:"twice per week", geName:"LIPOTOR",prescriptionDetails:"",prescriptionDate:"",apporvedBy:"",pharmacyDetails:"",refillDetails:""},
{ id: 2, name:"omeprazole 20 mg capsule", instructions:"once a day", geName:"HUGELEY",prescriptionDetails:"",prescriptionDate:"",apporvedBy:"",pharmacyDetails:"",refillDetails:""},
{ id: 3, name:"mometasone 50 mcg/actuation nasal spray", instructions:"two puffs weekly", geName:"RISON",prescriptionDetails:"",prescriptionDate:"",apporvedBy:"",pharmacyDetails:"",refillDetails:""},
{ id: 4, name:"TRULANCE 3 mg tablet", instructions:"3 times every 4 weeks", geName:"PLATEAU",prescriptionDetails:"",prescriptionDate:"",apporvedBy:"",pharmacyDetails:"",refillDetails:""},
{ id: 5, name:"omeprazole 40 mg capsule", instructions:"once a day 2mg", geName:"AXION",prescriptionDetails:"",prescriptionDate:"",apporvedBy:"",pharmacyDetails:"",refillDetails:""},
]

}