import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./messageCenter.css";
import { Table, Button, Tabs, Icon } from "antd";


const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const columns = [
  {
    title: "Received From",
    dataIndex: "name"
  },
  {
    title: "Subject",
    dataIndex: "age"
  },
  {
    title: "Date Time",
    dataIndex: "address"
  }
];

const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    key: i,
    //name: `Edward King ${i}`,
    name: `General Message`,
    age: `New Refill Request ${i}`,
    address: `01/04/2021 ${i}`
  });
}

class MessageCenter extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false
      });
    }, 1000);
  };

  onSelectChange = selectedRowKeys => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        
         <Icon type="mail" /> <h2>MESSAGES</h2>
          <br />
          
        <div style={{ marginBottom: 16 }}>
        <Link to="/AskDoctorForm"><Button type="primary">Compose</Button></Link>
        {/*<Button
            type="primary"
            onClick={this.start}
           // disabled={!hasSelected}
          //loading={loading}
          >
            Compose
        </Button>*/}
          <Button
            type="primary"
            onClick={this.start}
            disabled={!hasSelected}
            loading={loading}
          >
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>
        <Tabs onChange={callback} type="card">
    <TabPane tab="Inbox" key="1">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
bordered
        />
        </TabPane>
        <TabPane tab="Sent Messages" key="2">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          bordered
        />
        </TabPane>
        <TabPane tab="Deleted Messages" key="3">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
           bordered
        />
        </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default MessageCenter;