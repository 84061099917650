import React, { Component } from 'react';
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./refillPrescriptionList.css";
import { Table, Button, Tabs } from "antd";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const columns = [
  {
    title: "Medication Info",
    dataIndex: "name"
  },
  
];

const data = [];
for (let i = 0; i < 16; i++) {
  data.push({
    key: i,
    name: `Omeprazole 20 MG TAKE ONE CAPSULE BY MOUTH ONE TIME DAILY Oral 30 ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`
  });
}

class RefillPrescriptionList extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false
      });
    }, 1000);
  };

  onSelectChange = selectedRowKeys => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
       
       <div style={{ marginBottom: 16 }}>
       
       <Button
         type="primary"
         onClick={this.start}
         disabled={!hasSelected}
         loading={loading}
       >
         Refill Request
       </Button>
       <span style={{ marginLeft: 8 }}>
         {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
       </span>
     </div>
        
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
bordered
        />
      
       
      </div>
    );
  }
}
export default RefillPrescriptionList;