import React, { Component } from 'react';
import { login } from '../util/APIUtils';
//import './personal.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';

import { Descriptions, Card, Table } from 'antd';




const columns = [
    { title: 'Test', dataIndex: 'name', key: 'name' },
    { title: 'Ordered By', dataIndex: 'age', key: 'age' },
    { title: 'Date', dataIndex: 'address', key: 'address' },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: () => <a>View</a>,
    },
  ];
  
  const data = [
    {
      key: 1,
      name: 'CBC + DIFF',
      age: 'Dr Mirko',
      address: 'Sept 15. 2020',
      description: 'LDL is high, ARE YOU TAKING YOUR STATIN? If so would recommend dose increase. Here some general recommendations: Try reducing total and saturated fat in your diet, losing weight, performing aerobic exercise at least 30 minutes per day most days of the week, and eating a diet rich in fruits and vegetables, take a look online or at your local libray into Mediterranean Diet.',
    },
    {
      key: 2,
      name: 'COMP METABOLIC PANEL',
      age: 'Mirk A Meir Davila, MD',
      address: 'Sept 15, 2020',
      description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
    },
    {
      key: 3,
      name: 'FLORIDA LIPID PROFILE*_FL',
      age: 'Mirko A Meier Davila, MD',
      address: 'Sept 15, 2020',
      description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
    },
  ];

class LabResults extends Component {
  render() {
    return (
        <div>
         <h3>
         Test Results
         </h3>
     
     
     



         <Table
    columns={columns}
    expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
    dataSource={data}
  />,
    </div>
    );
  }
}

export default LabResults;