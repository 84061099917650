import React, { Component } from 'react';
import { Card, Col, Row, Icon, Button, Table, List, Typography, Divider, Descriptions } from 'antd';


export class MedicationDisplayList extends Component {

   
  render() {
    if (this.props.medications == null || this.props.medications.length === 0) {
        return <h5 className="p-2">No Medications</h5>
    }
           
    return this.props.medications.map(m => 
                  <div key={ m.id }>
                    <Card title= { m.name } 
                        style={{ width: 1000 }}
                        actions={[
                                        <Button type="primary" >Request a Renewal </Button>,
                                        <Button type="danger" >Remove </Button>
                                    ]}>
                        <p>Commonly known as: LIPITOR</p> 
                    <p>Learn more</p> 
                    <div><p><strong>Instructions:</strong> {m.instructions}.</p></div> 
                    <div style={{ background: '#ECECEC', padding: '15px' }}>
                    <Descriptions layout="vertical">
                        <Descriptions.Item label="Prescription Details">Prescribed November 30, 2020</Descriptions.Item>
                        <Descriptions.Item label="Refill Details">1810000000</Descriptions.Item>
                        <Descriptions.Item label="Approved By">Mirko A Meier Davila, MD</Descriptions.Item>
                        <Descriptions.Item label="Pharmacy Details" span={1}>
                        No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
                        </Descriptions.Item>
                    
                    </Descriptions>,
                        </div> <br />
                        

                        </Card> 
                        <br />
                        </div>
        );
   }
}