import React, { Component } from 'react';
import { login } from '../util/APIUtils';
//import './personal.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';

import { Descriptions, Card, Table } from 'antd';




const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];

const columns = [

  {
    title: 'Problems/Conditions',
    className: 'column-money',
    dataIndex: 'condition',
  },
  { 
  title: 'Onset Dates',
  dataIndex: 'onsetDate',

  },
  {
  title: 'Condition Status',
   dataIndex: 'status',  
},
{
title: 'Action',
dataIndex: 'onAction'
},
  
];


const columns2 = [
  
  {
    title: 'Location',
    className: 'column-money',
    dataIndex: 'money',
  },
  { 
  title: 'Date',
  dataIndex: 'code2',

  },
  {
  title: 'Provider',
   dataIndex: 'code3',  
},
{
title: 'Diagnosis',
dataIndex: 'code4'
}
  
];

const columns3 = [
  
  {
    title: 'Location',
    className: 'column-money',
    dataIndex: 'money',
  },
  { 
  title: 'Date',
  dataIndex: 'code2',

  },
  {
  title: 'Provider',
   dataIndex: 'code3',  
},
{
title: 'Diagnosis',
dataIndex: 'code4'
}
  
];

const data = [
  {
    key: '1',
    condition: 'Acute bilateral low back pain without sciatica',
    name: 'Problems',
    onsetDate:'16 Aug,2012',
    status: 'Active',
    onAction: 'Remove'   
   
  },
  {
    key: '2',
    name: 'Problems',
    condition: 'Acute gastritis without mention of hemorrhage',
    icd9:'534.5',
    icd10:'',
    onsetDate:'16 Aug,2012',
    conditionStatus: 'Active',
    snCode:'25458004'
  },
  {
    key: '3',
    name: 'Problems',
    condition: 'Unspecified abdominal pain',
    icd9:'',
    icd10:'R10.9',
    onsetDate:'16 Aug,2012',
    conditionStatus: 'Active',
    snCode:'21522001'
  },
  {
    key: '4',
    name: 'Problems',
    condition: 'Irritable bowel syndrome without diarrhea',
    icd9:'',
    icd10:'K21.9',
    onsetDate:'8 May,2015',
    conditionStatus: 'Active',
    snCode:'10743008'
  },
  {
    key: '5',
    name: 'Problems',
    condition: 'Gastro-esophageal reflux disease without esophagitisa',
    icd9:'',
    icd10:'K58.9',
    onsetDate:'16 Aug,2012',
    conditionStatus: 'Active',
    snCode:'266435005'
  },
];


const data2 = [
  {
    key: '1',
    name: 'Problems',
    money: 'Other specified disorder of gallbladder',
    address: 'New York No. 1 Lake Park',
  }
];

const data3 = [
  {
    key: '1',
    name: 'Problems',
    money: 'Other specified disorder of gallbladder',
    address: 'New York No. 1 Lake Park',
  }
];

class MedicalRecord extends Component {
  render() {
    return (
     <div>
      <Card title="Patient Details">
      <Descriptions  layout="vertical">
      <Descriptions.Item label="Patient Name">Kevin Matthews</Descriptions.Item>
      <Descriptions.Item label="Contact Info">1810000000</Descriptions.Item>
      <Descriptions.Item label="Live">Hangzhou, Zhejiang</Descriptions.Item>
      <Descriptions.Item label="Language" >
      English
    </Descriptions.Item>
    <Descriptions.Item label="Remark">empty</Descriptions.Item>
    </Descriptions>
    </Card>
    <br />
    <Card>
    <Descriptions>
    <Descriptions.Item label="Care Giver">Zhou Maomao  </Descriptions.Item> 
    <Descriptions.Item label="Contact Info">111511 NW 56 Drive , Coral Spring fl 33066</Descriptions.Item>
    
  </Descriptions>
  </Card>
<br />

<Table
    columns={columns}
    dataSource={data}
    bordered
    
  />,


<br />


<Card title="ALLERGIES">
No Known Allergies
</Card>
<br />

<Card title="ENCOUNTERS From 2019-12-16 To 2020-12-15">
<Table
    columns={columns3}
    dataSource={data3}
    bordered
    
  />,
</Card>
<br />

<Card title="IMMUNIZATIONS">

</Card>
<br />
<Card title="SOCIAL HISTORY">

</Card>
<br />
<Card title="REASON FOR REFERRAL">

</Card>
<br />
<Card title="VITAL SIGNS">

</Card>
<br />
<Card title="MEDICATIONS">

</Card>
<br />
<Card title="PROCEDURES">

</Card>
<br />
<Card title="RESULTS">

</Card>
<br />
<Card title="REASON FOR VISIT">

</Card>
<br />
<Card title="GOALS SECTION">

</Card>
<br />
<Card title="HEALTH CONCERNS">

</Card>
<br /> 
<Card title="MEDICAL EQUIPMENT">

</Card>
<br />
<Card title="MENTAL STATUS">

</Card>
<br />
<Card title="FUNCTIONAL STATUS">

</Card>
<br />
<Card title="ASSESSMENTS">

</Card>
<br />
<Card title="PLAN OF TREATMENT">

</Card>
<br />
<Card title="INSURANCE PROVIDERS">

</Card>
<br />
<Card title="CARE TEAM">

</Card>
    </div>
    );
  }
}

export default MedicalRecord;