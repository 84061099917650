import React, { Component } from 'react';
import './App.css';
import {
  Route,
  withRouter,
  Switch,
  Link
} from 'react-router-dom';

import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';

import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import Dashboard from '../dashboard/dashboard';
import AppHeaderMenu from '../common/AppHeaderMenu';


import { Layout, notification, Menu, Breadcrumb, Icon, Dropdown, Button } from 'antd';
import 'antd/dist/antd.css';

import PersonalInfo from '../pages/medicalRecord';
import AdditionalInfo from '../pages/additional';
import LookupRecord from '../pages/searchMedicalrecord';
import SummaryInfo from '../pages/summary';
import LabResults from '../pages/labResults';


//import MedicationList from '../pages/medicationList';
import ScheduleAppointment from '../pages/scheduleAppointment';
import MedicationList from '../pages/medicationList';
import HealthReport from '../pages/healthReport';
import RequestAppointment from '../pages/requestAppointment';
import MessageCenter from '../pages/messageCenter';
import RefillPrescriptionList from '../pages/refillPrescriptionList';
import AskDoctorForm from '../pages/askDoctorForm';
import CancelAppointment from '../pages/cancelAppointment';
import MyBills from '../pages/myBills';
import Insurance from '../pages/insurance';
import Registration from '../pages/registration';

import Card from '../pages/card';
import Square from '../pages/square';
import Label from '../pages/label';

import { PatientDataStore } from '../data/DataStore';
import { Provider } from "react-redux";
import { MedicationConnector } from "../components/patient/MedicationConnector";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;


class App extends Component {

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onCollapsed = collapsed => {

    console.log(collapsed);
    this.setState({collapsed});

  };

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });    
  }


  loadCurrentUser() {
    this.setState({
      isLoading: true
    });
    getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });  
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  handleLogout(redirectTo="/login", notificationType="success", description="You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    this.props.history.push(redirectTo);
    
    notification[notificationType]({
      message: 'Virtual Patient',
      description: description,
    });
  }

  handleLogin() {
    notification.success({
      message: 'Virtual Patient',
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/");
  }

  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }
    return <Provider store={PatientDataStore}>
      <Layout>
         {this.state.currentUser &&
        
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width="255">  
          <div className="logo">
          <Link to="/">
                <h1>Tamarac Medical Associates</h1>
               </Link>
</div>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          <Menu.Item key="1">
          <Link to="/">
          <Icon type= "dashboard" />
          
          <span className="nav-text">Dashboard</span>
         </Link> 
        </Menu.Item>
        <SubMenu
              key="sub2"
              title={
                <span>
                 <Icon type="calendar" />
                  <span>Appointments</span>
                </span>
              }
            >
              
               <Menu.Item key="8"><Link to="/ScheduleAppointment" className="nav-text"> Schedule an  Appointment</Link></Menu.Item>
              {/*<Menu.Item key="9"><Link to="/RequestAppointment" className="nav-text"> Request an Appointment</Link></Menu.Item>*/} 
             
              <Menu.Item key="10"><Link to="/CancelAppointment" className="nav-text">Cancel Appointments</Link>
              </Menu.Item>
             
              <Menu.Item key="12">COVID-19 Symptom Checkers</Menu.Item>
            </SubMenu>
          
            <SubMenu
              key="sub1"
              title={
                <span>
                 <Icon type="folder-add" />
                  <span>My Medical Records</span>
                </span>
              }
            >
               <Menu.Item key="5"><Link to="/MedicationList" className="nav-text">Medications </Link></Menu.Item>
              <Menu.Item key="3"> <Link to="/LookupRecord" className="nav-text">Search Personal Record</Link>
              </Menu.Item>
              <Menu.Item key="4"><Link to="/SummaryInfo" className="nav-text">Visit Summary</Link>
              </Menu.Item>
              <Menu.Item key="13"><Link to="/LabResults" className="nav-text">Test Results</Link>
              </Menu.Item>
              <Menu.Item key="6"><Link to="/HealthReport" className="nav-text">Health Trends</Link>
              </Menu.Item>
              
            </SubMenu>
           
           
            <SubMenu
              key="sub3"
              title={
                <span>
                 <Icon type="message" />
                  <span>Messagses</span>
                </span>
              }
            >
              <Menu.Item key="16"><Link to="/MessageCenter" className="nav-text">Inbox</Link></Menu.Item>
              <Menu.Item key="18"><Link to="/AskDoctorForm" className="nav-text">Ask a Doctor</Link></Menu.Item>
              <Menu.Item key="28">Immunization Form</Menu.Item>
            </SubMenu>
           
            <SubMenu
              key="sub5"
              title={
                <span>
                  <Icon type="money-collect" />
                  <span>Billing</span>
                </span>
              }
            >
              <Menu.Item key="6"><Link to="/MyBills" className="nav-text">My Bills</Link></Menu.Item>
              
            </SubMenu>
           
            <SubMenu
              key="sub19"
              title={
                <span>
                <Icon type="medicine-box" />
                  <span>Insurance</span>
                </span>
              }
            >
              <Menu.Item key="81"><Link to="/Insurance" className="nav-text">Insurance Summary</Link></Menu.Item>
              
            </SubMenu>
            <Menu.Item key="22">
              <Icon type="video-camera" />
              <span>Resources</span>
            </Menu.Item>
          </Menu>
         
        </Sider>
         }
        <Layout>
        {this.state.currentUser &&
          <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
             <AppHeaderMenu isAuthenticated={this.state.isAuthenticated} 
            currentUser={this.state.currentUser} 
            onLogout={this.handleLogout} />
          </Header>
        }
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
               <Switch>                               
                <Route exact path="/" 
                  render={(props) => <Dashboard isAuthenticated={this.state.isAuthenticated} 
                      currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
                </Route>
    
                              <Route path="/login" render={(props) => <Login onLogin={this.handleLogin} {...props} />}></Route>
                              <Route path="/signup" component={Signup}></Route>
                              <Route path="/PersonalInfo" component={PersonalInfo}></Route>
                              <Route path="/AdditionalInfo" component={AdditionalInfo}></Route>
                              <Route path="/LookupRecord" component={LookupRecord}></Route>
                              <Route path="/SummaryInfo" component={SummaryInfo}></Route>
                             {/* <Route path="/MedicationList" component={MedicationList}></Route>*/}
                             <Route path="/MedicationList" component={MedicationConnector}></Route>
                              <Route path="/ScheduleAppointment" component={ScheduleAppointment}></Route>
                              <Route path="/LabResults" component={LabResults}></Route>
                              <Route path="/HealthReport" component={HealthReport}></Route>
                              <Route path="/RequestAppointment" component={RequestAppointment}></Route>
                              <Route path="/MessageCenter" component={MessageCenter}></Route>
                              <Route path="/RefillPrescriptionList" component={RefillPrescriptionList}></Route>
                              <Route path="/AskDoctorForm" component={AskDoctorForm}></Route>
                              <Route path="/CancelAppointment" component={CancelAppointment}></Route>
                              <Route path="/MyBills" component={MyBills}></Route>
                              <Route path="/Insurance" component={Insurance}></Route>
                              <Route path="/Card" component={Card}></Route>
                              <Route path="/Square" component={Square}></Route>
<Route path="/label" component={Label}></Route>

                              {/*<Route path="/Registration" component={Registration}></Route>*/}
                              <Route path="/Registration" render={(props) => <Registration onLogin={this.handleLogin} {...props}/>}></Route>

                              <Route path="/users/:username"
                                  render={(props) => <Profile isAuthenticated={this.state.isAuthenticated}  currentUser={this.state.currentUser} {...props}  />}>
                              </Route>
                              <Route component={NotFound}></Route>
          </Switch>
        
          </Content>
          
          <br />
          <br />
          <br />
          <br />

            <Footer style={{ textAlign: 'center' }}>Tamarac Medical Associates ©2019 Created Netloop</Footer>
        </Layout>
      </Layout>
    );
    </Provider>
  }
}


  

export default withRouter(App);

