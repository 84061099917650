import React, { Component } from 'react';

import {

    Link,

    withRouter

} from 'react-router-dom';

//import './AppHeader.css';

//import pollIcon from '../poll.svg';

import styles from './AppHeader.css';

import { Layout, Menu, Dropdown, Icon, message } from 'antd';

const Header = Layout.Header;

 

   

class AppHeaderMenu extends Component {

  constructor(props) {

    super(props);  

    this.handleMenuClick = this.handleMenuClick.bind(this);  

}

 

handleMenuClick({ key }) {
   
  if(key === "logout") {

    this.props.onLogout();

  }

}

 

    render() {

    

        let menuItems;

        if(this.props.currentUser) {

          menuItems = [

            <Menu.Item key="/">

              <Link to="/">

                <Icon type="home" className="nav-icon" />

              </Link>

            </Menu.Item>,

            <Menu.Item key="/">

            <Link to="/signup">

              <Icon type="plus" className="nav-icon" />

            </Link>

          </Menu.Item>,

          <Menu.Item key="/profile" className="profile-menu">

                <ProfileDropdownMenu

                  currentUser={this.props.currentUser}

                  handleMenuClick={this.handleMenuClick}/>

            </Menu.Item>

          ];

        }

        return (

       

          <Menu

                className="app-menu"

                mode="horizontal"

                selectedKeys={[this.props.location.pathname]}

                style={{ lineHeight: '64px' }} >

                  {menuItems}

              </Menu>

        ); 

    }

}

 

function ProfileDropdownMenu(props) {

  const dropdownMenu = (

    <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu">

      <Menu.Item key="user-info" className="dropdown-item" disabled>

        <div className="user-full-name-info">

          {props.currentUser.name}

        </div>

        <div className="username-info">

          @{props.currentUser.username}

        </div>

      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="profile" className="dropdown-item">

        <Link to={`/users/${props.currentUser.username}`}>Profile</Link>

      </Menu.Item>
  
      <Menu.Item key="logout" className="dropdown-item">

        Logout

      </Menu.Item>

    </Menu>

  );

 

  return (

    <Dropdown

      overlay={dropdownMenu}

      trigger={['click']}

      getPopupContainer = { () => document.getElementsByClassName('profile-menu')[0]}>

      <a className="ant-dropdown-link">

         <Icon type="user" className="nav-icon" style={{marginRight: 0}} /> <Icon type="down" />

      </a>

    </Dropdown>

  );

}

 

function DashboardDropdownMenu(props) {

  const menu = (

    <Menu>

      <Menu.Item>

        <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">TRR</a>

      </Menu.Item>

      <Menu.Item>

        <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">AM</a>

      </Menu.Item>

      <Menu.Item>

        <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">TCM</a>

      </Menu.Item>

    </Menu>

  );

 

  return (

    <Dropdown overlay={menu}>

    <a className="ant-dropdown-link" href="#">

      Dashboard <Icon type="down" />

    </a>

  </Dropdown>

  );

}

 

export default withRouter(AppHeaderMenu);