import React, {Component} from 'react'


class Label extends Component {

render(){
  var labelStyle = {

    fontFamily: "sans-serif",
    fontWeigt: "bold",
    padding: 13,
    margin: 0
  };

    return (
      
        <p style={labelStyle}>#FF6663



        </p>

    );
}

}

export default Label