
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
//import 'antd/dist/antd.css';
import './searchMedicalrecord.css';
import { Form, Input, Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import PersonalInfo from '../pages/medicalRecord';
const { RangePicker } = DatePicker;

function onChange(dates, dateStrings) {
  console.log('From: ', dates[0], ', to: ', dates[1]);
  console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
}


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};

class SearchMedicalrecord extends Component {
 
  formRef = React.createRef();
  onGenderChange = (value) => {
    switch (value) {
      case 'male':
        this.formRef.current.setFieldsValue({
          note: 'Hi, man!',
        });
        return;

      case 'female':
        this.formRef.current.setFieldsValue({
          note: 'Hi, lady!',
        });
        return;

      case 'other':
        this.formRef.current.setFieldsValue({
          note: 'Hi there!',
        });
        return;
    }
  };
  onFinish = (values) => {
    console.log(values);
  };
  onReset = () => {
    this.formRef.current.resetFields();
  };
  onFill = () => {
    this.formRef.current.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
  };

  /*render() {
    return (
      <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
        <Form.Item
          name="note"
          label="From Date:*"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="note"
          label="To Date:*"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
        >
          {({ getFieldValue }) => {
            return getFieldValue('gender') === 'other' ? (
              <Form.Item
                name="customizeGender"
                label="Customize Gender"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="input placeholder" />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button htmlType="button" onClick={this.onReset}>
            Cancel
          </Button>
        </Form.Item>
      </Form>*/

      render() {
        return (


<div>    
  <h3>PERSONAL HEALTH RECORD</h3>  
  

<Form {...layout}>

<Form.Item name="picker" label="Select health record for date range of" rules={[{ required: true }]}>
<div>
    
<DatePicker onChange={onChange} placeholder="From Date"  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<DatePicker onChange={onChange} placeholder="To Date"  />
  </div>


  </Form.Item>
  <Form.Item {...tailLayout}>
  <Link to="/PersonalInfo"><Button type="primary" htmlType="submit">
            Submit
          </Button></Link>
          <Button htmlType="button" onClick={this.onReset}>
            Cancel
          </Button>

         
        </Form.Item>
  </Form>
  <h4>
  Disclaimer: The following sections represent the most current data on record with your provider(s) and are not specific to the date range you have selected: Demographics, Social History, Problems, Medications, Allergies, Immunizations, Health Concerns, and Medical Equipment (UDI).
  </h4>
</div>


    );
  }
}
export default SearchMedicalrecord;