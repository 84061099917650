import React, { Component } from "react"

import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Card, Typography, Descriptions, Upload, Icon, message, Button, Divider, Modal } from 'antd';
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const gridStyle = {
    width: '50%',
    //textAlign: 'center',
  };

  const gridStyle2 = {
    width: '50%',
    textAlign: 'center',
  };

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const { confirm } = Modal;

function showConfirm() {
  confirm({
    title: 'Do you Want to delete these items?',
    content: 'Some descriptions',
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

function showDeleteConfirm() {
  confirm({
    title: 'Are you sure delete this task?',
    content: 'Some descriptions',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

function showPropsConfirm() {
  confirm({
    title: 'Are you sure you want to remove this Coverage',
    content: 'United Healthcare / UNITED HEALTHCARE',
    okText: 'Yes',
    okType: 'danger',
    okButtonProps: {
      disabled: false,
    },
    cancelText: 'No',
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

class Insurance extends Component{

    state = {
        loading: false,
      };
    
      handleChange = info => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
              imageUrl,
              loading: false,
            }),
          );
        }
      };

render(){
    const uploadButton = (
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>
        </div>
      );

      const { imageUrl } = this.state;
   return(
    <div style={{ background: '#EFF2FB', padding: '25px', width:'10'}}>
    
    <Title level={4}>Insurance on File</Title>
    <Paragraph>
    Please upload your insurance card below. Uploading your insurance card saves you time at check-in by allowing us to process your information prior to your appointment.
    For assistance, view Steps to Upload your Insurance Card.
     
    </Paragraph>
    <Card>
    <Card.Grid style={gridStyle}>
        
    <Descriptions title="United Healthcare / UNITED HEALTHCARE" layout="vertical"> 
    <Descriptions.Item label="Sunscriber Name">Kevin Matthews</Descriptions.Item> 
    <Descriptions.Item label="Subscriber Number">943952385</Descriptions.Item> 

    <Descriptions.Item label="Group Number">708493</Descriptions.Item>
 
  </Descriptions>

  <div>
  <Icon type="edit" />&nbsp;&nbsp; <Link href ="#Kevin" onClick={showConfirm}>Update Coverage</Link><br />

  <Icon type="upload" />&nbsp;&nbsp;<Link href ="#Kevin" onClick={showDeleteConfirm} >
      Replace Insurance
    </Link> <br />
    <Icon type="delete" /> &nbsp;&nbsp;<Link href ="#Kevin" onClick={showPropsConfirm}>
      Remove Coverage
    </Link>
  </div>
  
  <br />
  <br />
  
 </Card.Grid> 
  
 <Card.Grid style={gridStyle}>

 <h2>Add a coverage</h2>
 <br />
  <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        <br />
        <br />
        <br />
        <br />
      </Upload>

 </Card.Grid>
    </Card>
    <br />
    <Button type="primary">

       Back to the home page
    </Button>

  </div>

   );

}


} 
export default Insurance;
