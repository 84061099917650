import React, { Component } from "react";
import { Switch, Route, Redirect } 
    from "react-router-dom"
import { connect } from "react-redux";
import { loadData } from "../../data/ActionCreator";
import { DataTypes } from "../../data/Types";
import { Medication } from "../patient/Medication";



const mapStateToProps = (dataStore) => ({
    ...dataStore
})

const mapDispatchToProps = {
    loadData
}


    
export const MedicationConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Route path="/MedicationList"
            render={ (routeProps) => 
               <Medication { ...this.props } { ...routeProps } medications={ this.props.medications} />

            }/>

        }
    

        componentDidMount() {
            this.props.loadData(DataTypes.MEDICATIONS);
           
        }
    }
)
