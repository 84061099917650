import React, { Component } from 'react';
import { login } from '../util/APIUtils';
import './scheduleAppointment.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';

import { List, Card, Steps, Button, message, Radio, Checkbox } from 'antd';



const { Step } = Steps;


const plainOptions = ['Apple', 'Pear', 'Orange'];
const defaultCheckedList = ['Apple', 'Orange'];

const data2 = [
  {
    title: 'Mirko A Meire Davila, MD',
    description: 'Primary Care Provider',
  },
  {
    title: 'Kevin Lucas, DO',
    description: 'Spine Institue',
  },
  {
    title: 'Michelle Williams, MD',
    description: 'Pulmonary Disease',
  },
  {
    title: 'Dark Vader, MD',
    description: 'Gastroenterology',
  },
  {
    title: 'Patrice Robinson, APRN',
    description: 'Endocrinology',
  },
  {
    title: 'Robert Nester, MD',
    description: 'Internal Medicne',
  },
];

const gridStyle = {
  width: '100%',
  textAlign: 'center',
};

const data = [
  'For a non urgent, non-emergency health issue',
  'For follow up a prvious appoitnment',
  'Cannot be used to schdule Covid',
 
];

const location = [
  'Any Location',
  'Parkland N University Dr',
  'Weston',
 
];

const time = [
  'Wednesday October 25th - 10:15 am',
  'Thursday  October 25th - 10:15 am',
  'Friday    October 25th - 10:15 am',
  'Monday    October 25th - 10:15 am',
  'Tuesday   October 25th - 10:15 am',
  'Wednesday October 25th - 10:15 am',
  'Wednesday October 25th - 10:15 am',
  'Wednesday October 25th - 10:15 am',
 
];


const value4 = [
  'kevins'

];

function onChange(checkedValues) {
  console.log('checked = ', checkedValues);
}

const options = [
  { label: 'Kevin Matthews, MD Primary Care', value: 'Apple' },
  { label: 'Robert Mackenzie, MD Allergy', value: 'Pear' },
  { label: 'Dr Alexandra Vasquez', value: 'Orange' },
];



const CheckboxGroup = Checkbox.Group;
const careOptions = ['Apple', 'Pear', 'Orange'];

const steps = [
  {
    title: 'Providers',
    content: <div>
   <div><h1>Schedule with a specific Provider</h1></div>
    <Checkbox.Group options={options}  onChange={onChange} />
    
  </div>
  },
  {
    title: 'Reason for visit',
    content:  <div> What Kind of Appointment are you Scheduling?<List
    size="small"
    header={<div><h1>Primary Care</h1></div>}
    bordered
    dataSource={data}
    renderItem={item => <List.Item><Checkbox onChange={onChange}>{item}</Checkbox></List.Item>} 
  /></div>
  },

  /*{
    title: 'Reason for Visit',
    content:  <div>
    <div style={{ borderBottom: '1px solid #E9E9E9' }}>
      <Checkbox
        indeterminate={this.state.indeterminate}
        onChange={this.onCheckAllChange}
        checked={this.state.checkAll}
      >
        Check all
      </Checkbox>
    </div>
    <br />
    <CheckboxGroup
      options={plainOptions}
      value={this.state.checkedList}
      onChange={this.onChange}
    />
  </div>


  },*/
  {
    title: 'Location',
    content: <div> Which location work for you?
    <List
    size="small"
    bordered
    dataSource={location}
    renderItem= {item => <List.Item><Radio>{item}</Radio></List.Item>}
  /></div>
  },
  {
    title: 'Time',
    content: <div>'What time works for you?'<List
    size="small"
    bordered
    dataSource={time}
    renderItem= {item => <List.Item><Radio>{item}</Radio></List.Item>}
  /></div>

  },
  {
    title: 'Verify and schedule',
    content: 'Verify your personal information',
  },
];

class ScheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      checkedList: defaultCheckedList,
      indeterminate: true,
      checkAll: false,
    
    };
  }

  state = {
    value1: 'Apple',
    value2: 'Apple',
    value3: 'Apple',
    value4: 'Apple',
  };

  onChange4 = e => {
    console.log('radio4 checked', e.target.value);
    this.setState({
      value4: e.target.value,
    });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

/*
  onChange = checkedList => {
    this.setState({
      checkedList,
      //indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      //checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = e => {
    this.setState({
      //checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };
*/
  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };


  render() {
    const { current } = this.state;
    return (
      <div>
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}
         
        </div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ScheduleAppointment;