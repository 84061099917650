import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import { Form, Icon, Input, Button} from 'antd';

const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AskDoctorForm extends Component {
  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    return (
     <div>
       <h2>COMPOSE NEW GENERAL MESSAGE</h2><br />
Please use messages for non-urgent communication only.
If this is an URGENT or EMERGENT patient care issue,<br />
 please call 911. DO NOT LEAVE A MESSAGE HERE.
 <br /> <br />
      <Form layout="inline" onSubmit={this.handleSubmit}>
        
        <div>
        To: 
        <br />
        <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
          {getFieldDecorator('To', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
          
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', maxLength:15 }} />}
              placeholder="Search Provider By Last Name"
              maxLength="24"
              minLength="24"
            />,
          )}
        </Form.Item>
      
        Facility:
        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
          {getFieldDecorator('Facility', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              maxLength="54"
              //placeholder="Password"
            />,
          )}
        </Form.Item>
        </div>
        <br />
        Subject:
        <br />
        <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
          {getFieldDecorator('To', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
          
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', maxLength:55 }} />}
              placeholder="Search Provider By Last Name"
              maxLength="44"
              minLength="44"
            />,
          )}
        </Form.Item>
        <br />
        Message
        <br />
        <TextArea
         
          autoSize={{ minRows: 4, maxRows: 4, maxLength: 1, maxLength: 1}}
          
        />
        <div>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Submit
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Cancel
          </Button>
        </Form.Item>
        </div>
      </Form>
      </div>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create({ name: 'horizontal_login' })(AskDoctorForm);



export default Form.create()(AskDoctorForm);

          