import React, { Component } from 'react';
import { MedicationDisplayList } from "./medicationDisplayList";

export class Medication extends Component {
         
    render() {

        return(
         <div>
            <MedicationDisplayList medications={this.props.medications}/>
         </div>

        )
    }
}