import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { getCurrentUser } from '../util/APIUtils';
import 'antd/dist/antd.css';
import './dashboard.css'
import { Card, Col, Row, Icon, Button, Table, List, Typography, Divider } from 'antd';

const { Paragraph, Title, Text } = Typography;
const data = [
  'atorvastatin 20 mg tablet',
  'omeprazole 20 mg capsuleTrulance',
  'mometasone 50 mcg/actuation nasal spray',

];


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {

   
      isLoading: false
    }
   
    
  }
  
   render() {

    return (
      
      this.props.currentUser ? (
         
<div>
<Typography>
   
   <Title level={4}>Hi {this.props.currentUser.name} </Title>
<Paragraph>
Welcome to the Tamarac Medical Associates Portal! The Patient Portal provides a secure communication channel between you and your physician and allows patients to be proactive in the management of their own health. You can now review your medical history, request appointments, send messages and review clinical results.
   </Paragraph>

</Typography>

<br />


    <Card title="Current Medication" type="inner" extra={<Link to="/MedicationList">View All</Link>}
    
    actions={[

      <Link to="/MedicationList"><Button type="primary"> Request Refill</Button></Link>
    ]}
    >
  
    <List
                    size="small"
                    dataSource={data}
                    IIcon type="chrome"
                    renderItem={item => <List.Item>{item}</List.Item>}
                  />
    </Card>
    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title="Medical Records"
      extra={<Link to="/LookUpRecord">View All</Link>}
      actions={[
        <Link to="/LookUpRecord"><Button type="primary">View Medical Records</Button></Link>

      ]}
    >
    Personal Health Record can be requested by clicking on the Request PHR below

Request
<br />
<br />
<br />

<Link to="/LookupRecord">
  Request PHR
</Link>    
    </Card>

    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title="Bill Pay"
      extra={<a href="#">More</a>}
      actions={[
        <Link to="/MyBills"><Button type="primary">Pay My Bill</Button></Link>

      ]}
    >
       Please check your latest statement. If payment is pending check details. Bill Date:12/09/2020
    </Card>
 
  </div>


      ) : null
    );



  }

}








 





export default withRouter(Dashboard);