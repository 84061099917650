import React, { Component } from 'react';
import { login } from '../util/APIUtils';
//import './personal.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';

import { Descriptions, Card, Table } from 'antd';




const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];

const columns = [

  {
    title: 'Problems/Conditions',
    className: 'column-money',
    dataIndex: 'condition',
  },
  { 
  title: 'Onset Dates',
  dataIndex: 'onsetDate',

  },
  {
  title: 'Condition Status',
   dataIndex: 'status',  
},
{
title: 'Action',
dataIndex: 'onAction'
},
  
];


const columns2 = [
  
  {
    title: 'Location',
    className: 'column-money',
    dataIndex: 'money',
  },
  { 
  title: 'Date',
  dataIndex: 'code2',

  },
  {
  title: 'Provider',
   dataIndex: 'code3',  
},
{
title: 'Diagnosis',
dataIndex: 'code4'
}
  
];

const columns3 = [
  
  {
    title: 'Date',
    className: 'column-date',
    dataIndex: 'date',
  },
  {
    title: 'Physician',
    className: 'column-name',
    dataIndex: 'pname',
  },
  { 
  title: 'Address',
  dataIndex: 'address',

  },
 
{
    title: 'Reason',
     dataIndex: 'reason',  
  },
  {
    title: 'Action',
     dataIndex: 'action',  
  },
  
];






const data3 = [
  {
    key: '1',
    action: 'Visit Summary',
    date: '12/1/2020',
    address: 'New York No. 1 Lake Park',
    pname: 'Dr Bitman',
    reason:'adominal pain'
  },
  {
    key: '2',
    action: 'Visit Summary',
    date: '12/1/2020',
    address: 'New York No. 1 Lake Park',
    pname: 'Dr Bitman',
    reason:'adominal pain'
  },
  {
    key: '3',
    action: 'Visit Summary',
    date: '12/1/2020',
    address: 'New York No. 1 Lake Park',
    pname: 'Dr Bitman',
    reason:'adominal pain'
  },
  {
    key: '4',
    action: 'Visit Summary',
    date: '12/1/2020',
    address: 'New York No. 1 Lake Park',
    pname: 'Dr Bitman',
    reason:'adominal pain'
  },
  {
    key: '5',
    action: 'Visit Summary',
    date: '12/1/2020',
    address: 'New York No. 1 Lake Park',
    pname: 'Dr Bitman',
    reason:'adominal pain'
  }
];

class Summary extends Component {
  render() {
    return (
        <div>
         <h3>
            Visit Summary 
         </h3>
     
     
     


<Table
    columns={columns3}
    dataSource={data3}
    bordered
    
  />,

    </div>
    );
  }
}

export default Summary;