
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
//import './index.css';
import { Card, Col, Row,Descriptions, Badge,Button, Table } from 'antd';


const columns = [
    {
      title: 'DATE OF SERVICE',
      dataIndex: 'name',
    },
    {
      title: 'DESCRIPTION OF SERVICE',
      dataIndex: 'age',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'address',
    },
    {
        title: 'PAYMENTS',
        dataIndex: 'payments',
      },
      {
        title: 'BALANCE',
        dataIndex: 'balance',
      },
  ];
  const data = [
    {
      key: '1',
      name: '2020-11-20',
      age: 'Claim:2046800, Provider: Stewart W Bitman, MD',
      address: '',
      payments:'',
    },
    {
      key: '2',
      name: '2020-11-20',
      age: 'Facility: FL047 North Broward',
      address: '',
      payments: 120.62,
    },
    {
      key: '3',
      name: '2020-12-04',
      age: 'UHC OPEN ACCESS Payment',
      address: '',
      payments: 391.23
    },
    {
        key: '4',
        name: '',
        age: 'Your Balance Due on These Services',
        address: '',
        payments: '',
        balance: 30.15,
      },
  ];
class MyBills extends Component {


    
    render() {
        return(
  <div style={{ padding: '30px' }}>
      <h2>
      BILLING SUMMARY

      </h2>
      <br />
      <Row gutter={96}>
      <Col span={10}>
    <Card title="Patient Details"  style={{ width: 465 }}>
    <Descriptions  layout="vertical">
    <Descriptions.Item label="PATIENT'S NAME">Kevin A Matthews</Descriptions.Item>
    <Descriptions.Item label="BILL DATE">2020-12-12-09</Descriptions.Item>
    <Descriptions.Item label="ACCT">3473295</Descriptions.Item>
    
    <Descriptions.Item label="ADDRESS">
      Kevin A Matthews
      <br />
      11660 NW 56TH DR
      <br />
      APT 103
      <br />
      CORAL SPRINGS FL 33076-3158
     
    </Descriptions.Item>
  </Descriptions>
    </Card>
    
    </Col>
    <Col span={9}>
    <Card title="Bill Amount Due : $190" style={{ width: 400 }}>
      <div>
      <p>Statement Balance: $190.40</p>
      <Button block="false" type="primary" size="medium">Make Payment</Button><br /><br />
      <Button block>CHANGE PAYMENT AMOUNT</Button>
      </div>
    </Card>
    </Col>
    </Row>
     <br />
    <Card title="Service Summary"  style={{ width: 865 }}>
    
    <Table bordered="false" columns={columns} dataSource={data} size="small" />
    </Card>
   <br />
    
  </div>


  
);
        }
}

export default MyBills;