import React, { Component } from 'react';
import { login } from '../util/APIUtils';
//import './personal.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import 'antd/dist/antd.css';
import { List, Avatar, message, Spin } from 'antd';
import reqwest from 'reqwest';

import InfiniteScroll from 'react-infinite-scroller';
const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];


  const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';

class HealthReport extends Component {
    state = {
        data: [],
        loading: false,
        hasMore: true,
      };
    
      componentDidMount() {
        this.fetchData(res => {
          this.setState({
            data: res.results,
          });
        });
      }
    
      fetchData = callback => {
        reqwest({
          url: fakeDataUrl,
          type: 'json',
          method: 'get',
          contentType: 'application/json',
          success: res => {
            callback(res);
          },
        });
      };
    
      handleInfiniteOnLoad = () => {
        let { data } = this.state;
        this.setState({
          loading: true,
        });
        if (data.length > 14) {
          message.warning('Infinite List loaded all');
          this.setState({
            hasMore: false,
            loading: false,
          });
          return;
        }
        this.fetchData(res => {
          data = data.concat(res.results);
          this.setState({
            data,
            loading: false,
          });
        });
      };
    
      render() {
        return (
            
          <div className="demo-infinite-container">
                           <div>
                           <strong><h2>Health Reports</h2></strong>
                           <p>The reports listed below reflect the last 15 vital signs documented in your electronic medical record.</p>
                           <h1>Available Reports</h1>
                           </div>



            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.handleInfiniteOnLoad}
              hasMore={!this.state.loading && this.state.hasMore}
              useWindow={false}
            >
              <List
                dataSource={this.state.data}
                renderItem={item => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                    
                      /*title={<a href="https://ant.design">{item.name.last}</a>}
                      description={item.email}*/
                      title={<a href="https://ant.design">{item.name.last}</a>}
                    />
                    
                  </List.Item>
                )}
              >
                {this.state.loading && this.state.hasMore && (
                  <div className="demo-loading-container">
                    <Spin />
                  </div>
                )}
              </List>
            </InfiniteScroll>
          </div>
        );
      }
    }

export default HealthReport;